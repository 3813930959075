<template>
  <div>
    <Header />
    <div class="page-content">
      <section class="services top-padding-zero" data-aos="zoom-in">
        <div class="container">
          <h2 class="sectio-title">{{ $t("privacy.privacy") }}</h2>
          <div class="desc text-left full-size">
            {{ $t("privacy.privacy-desc") }}
          </div>
          <h3 class="card-title text-left">
            {{ $t("privacy.privacy-title-1") }}
          </h3>
          <div class="desc text-left full-size">
            {{ $t("privacy.privacy-desc-1") }}
          </div>
          <h3 class="card-title text-left">
            {{ $t("privacy.privacy-title-2") }}
          </h3>
          <div class="terms-sub">1) {{ $t("privacy.privacy-desc-2-1") }}</div>
          <div class="terms-sub">2) {{ $t("privacy.privacy-desc-2-2") }}</div>
          <div class="terms-sub">3) {{ $t("privacy.privacy-desc-2-3") }}</div>
          <div class="terms-sub">4) {{ $t("privacy.privacy-desc-2-4") }}</div>
          <div class="terms-sub bottom-25">
            5) {{ $t("privacy.privacy-desc-2-5") }}
          </div>
          <h3 class="card-title text-left">
            {{ $t("privacy.privacy-title-3") }}
          </h3>
          <div class="desc text-left full-size">
            {{ $t("privacy.privacy-desc-3") }}
          </div>
          <div class="terms-sub">1) {{ $t("privacy.privacy-desc-3-1") }}</div>
          <div class="terms-sub">2) {{ $t("privacy.privacy-desc-3-2") }}</div>
          <div class="terms-sub">3) {{ $t("privacy.privacy-desc-3-3") }}</div>
          <div class="terms-sub">4) {{ $t("privacy.privacy-desc-3-4") }}</div>
          <div class="terms-sub bottom-25">
            5) {{ $t("privacy.privacy-desc-3-5") }}
          </div>
          <h3 class="card-title text-left">
            {{ $t("privacy.privacy-title-4") }}
          </h3>
          <div class="desc text-left full-size">
            {{ $t("privacy.privacy-desc-4") }}
          </div>
          <h3 class="card-title text-left">
            {{ $t("privacy.privacy-title-5") }}
          </h3>
          <div class="desc text-left full-size">
            {{ $t("privacy.privacy-desc-5") }}
          </div>
          <h3 class="card-title text-left">
            {{ $t("privacy.privacy-title-6") }}
          </h3>
          <div class="desc text-left full-size">
            {{ $t("privacy.privacy-desc-6") }}
          </div>
          <h3 class="card-title text-left">
            {{ $t("privacy.privacy-title-7") }}
          </h3>
          <div class="desc text-left full-size">
            {{ $t("privacy.privacy-desc-7") }}
          </div>
          <div class="terms-sub">1) {{ $t("privacy.privacy-desc-7-1") }}</div>
          <div class="terms-sub">2) {{ $t("privacy.privacy-desc-7-2") }}</div>
          <div class="terms-sub">3) {{ $t("privacy.privacy-desc-7-3") }}</div>
          <div class="terms-sub">4) {{ $t("privacy.privacy-desc-7-4") }}</div>
          <div class="terms-sub">5) {{ $t("privacy.privacy-desc-7-5") }}</div>
          <div class="terms-sub">6) {{ $t("privacy.privacy-desc-7-6") }}</div>
          <div class="terms-sub bottom-25">
            7) {{ $t("privacy.privacy-desc-7-7") }}
          </div>
          <h3 class="card-title text-left">
            {{ $t("privacy.privacy-title-8") }}
          </h3>
          <div class="desc text-left full-size">
            {{ $t("privacy.privacy-desc-8") }}
            <a href="#">{{ $t("privacy.privacy-desc-8-1") }}</a>
            {{ $t("privacy.privacy-desc-8-2") }}
            <a href="#">{{ $t("privacy.privacy-desc-8-1") }}</a>
            {{ $t("privacy.privacy-desc-8-3") }}
          </div>
          <h3 class="card-title text-left">
            {{ $t("privacy.privacy-title-9") }}
          </h3>
          <div class="desc text-left full-size">
            {{ $t("privacy.privacy-desc-9") }}
          </div>
          <h3 class="card-title text-left">
            {{ $t("privacy.privacy-title-10") }}
          </h3>
          <div class="desc text-left full-size">
            {{ $t("privacy.privacy-desc-10") }}
          </div>
          <h3 class="card-title text-left">
            {{ $t("privacy.privacy-title-11") }}
          </h3>
          <div class="desc text-left full-size">
            {{ $t("privacy.privacy-desc-11") }}
          </div>
          <h3 class="card-title text-left">
            {{ $t("privacy.privacy-title-12") }}
          </h3>
          <div class="desc text-left full-size">
            {{ $t("privacy.privacy-desc-12") }}
          </div>
        </div>
      </section>
    </div>
    <Footer />
  </div>
</template>

<script>
import {
  BButton,
  BImg,
  BNavbarNav,
  BNavbarToggle,
  BNavbar,
  BCollapse,
  BRow,
  BCol,
  BCard,
  BCardText
} from "bootstrap-vue";
import store from "@/store/index";
import Locale from "@/layouts/components/Locale.vue";
import Contact from "@/assets/images/pages/Contact.svg";
import Logo from "@/assets/rashiCoin/logo.svg";
import Header from "./../Header.vue";
import Footer from "./../Footer.vue";
export default {
  components: {
    BButton,
    BImg,
    BNavbarNav,
    BNavbarToggle,
    BNavbar,
    BCollapse,
    Locale,
    BRow,
    BCol,
    BCard,
    BCardText,
    Contact,
    Logo,
    Header,
    Footer
  },
  data() {
    return {
      isActive: 1,
      lang: this.$route.params.lang
    };
  },
  mounted() {},
  methods: {
    changeTab(tab) {
      this.isActive = tab;

      if (tab === 2) {
        document.querySelector(".tab-slider--tabs").classList.add("slide");
      } else {
        document.querySelector(".tab-slider--tabs").classList.remove("slide");
      }
    },
    openPage(props) {
      this.$router.push({
        name: props,
        params: { lang: this.lang || undefined }
      });
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        return this.downImg;
      }
      return this.downImg;
    }
  }
};
</script>

<style lang="scss">
@import "../../assets/landing/css/style.scss";
@import "@core/scss/vue/pages/page-misc.scss";
</style>
<style lang="scss" scoped>
.page-content {
  min-height: 90vh;
  padding-top: 0px;
}
.cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0px 90px;
}
.services .card h3.without-img {
  margin-top: 0px;
}
.full-size {
  width: 100%;
}
.desc.text-left.full-size {
  margin-bottom: 25px;
}
.terms-sub {
  padding-left: 20px;
  font-size: 14px;
  margin-bottom: 5px;
}
.terms-sub-sub {
  padding-left: 40px;
  font-size: 12px;
  margin-bottom: 5px;
}
.bottom-25 {
  margin-bottom: 25px;
}
</style>
